<template>
  <v-card v-if="username">
    <v-toolbar height="64px" v-if="username">
      <v-btn small fab color="primary" to="/" :disabled="preCheckInIconDisabled">
        <v-icon medium>contact_mail</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      
      <v-btn small fab color="primary" to="/guide" :disabled="guideIconDisabled">
        <v-icon medium>directions</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      
      <v-menu :nudge-width="100">

        <template v-slot:activator="{ on }">
          <v-btn small fab color="primary" v-on="on">
            <v-icon medium>language</v-icon>
          </v-btn>
        </template>
        
        <v-list>
          <v-list-tile
            v-for="item in languages"
            :key="item"
            @click="switchLocale(item)"
          >
            <v-list-tile-title>{{ $t( "menu." + item) }}</v-list-tile-title>
            <flag :iso="item" />
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>

      <v-btn small fab color="primary" @click.native="logout">
        <v-icon medium>exit_to_app</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import FlagIcon from 'vue-flag-icon'
import { EventBus } from '@/eventBus'
import * as utils from '@/utils'

Vue.use(FlagIcon)

export default {
  name: 'Menu',
  data: () => ({
    languages: [
      'gb', 'hk', 'jp'
    ],
    guideIconDisabled: false,
    preCheckInIconDisabled: false
  }),
  created () {
  },
  mounted () {
    this.guideIconDisabled = !this.allIdVerified
    this.preCheckInIconDisabled = this.allIdVerified
  },
  computed: mapState({
    username: state => state.session.username,
    ...mapState('checkIn', {
      members: 'members',
      reservation: 'reservation'
    }),
    ...mapGetters('checkIn', {
      allIdVerified: 'allIdVerified',
    })
  }),
  watch: {
    members: {
      handler: function (val, oldVal) {
        // console.log('members', val)
        // console.log('allIdVerified', this.allIdVerified)
        this.guideIconDisabled = !this.reservation.isIdVerified
        this.preCheckInIconDisabled = this.reservation.isIdVerified
      },
      deep: true
    },
  },
  methods: {
    switchLocale (item) {
      this.$i18n.locale = item
    },
    ...mapActions({
      logout: 'session/logout',
    })
  },
}
</script>